import React from 'react';
import { AppSettings } from './config/app-settings.js';
import { slideToggle } from './composables/slideToggle.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content';

import { pingUser } from "./actions/AuthAction";
import { storageInteractor } from "./utils/storageInteractor";
import ModalGeneral from './components/modal/modalGeneral';

interface IUserDataLogin {
  userData: {
    user_name: string;
    full_name: string;
    email: string;
    user_tipo_id: string;
    user_tipo_des: string;
    required_duo: string;
    data_host: string;
    data_sign_request: string;
		inactivity_time: number;
  };
  authenticated: boolean;
  authenticatedDuo: boolean;
}

type IAppState = {
	appTheme: string;
	appDarkMode: boolean;
	appGradientEnabled: boolean;
	
	appHeaderNone: boolean;
	appHeaderFixed: boolean;
	appHeaderInverse: boolean;
	appHeaderMegaMenu: boolean;
	appHeaderLanguageBar: boolean;
	hasScroll: boolean;
	handleSetAppHeaderNone: any;
	handleSetAppHeaderInverse: any;
	handleSetAppHeaderLanguageBar: any;
	handleSetAppHeaderMegaMenu: any;
	handleSetAppHeaderFixed: any;
	
	appSidebarNone: boolean;
	appSidebarWide: boolean;
	appSidebarLight: boolean;
	appSidebarMinify: boolean;
	appSidebarMobileToggled: boolean;
	appSidebarTransparent: boolean;
	appSidebarSearch: boolean;
	appSidebarFixed: boolean;
	appSidebarGrid: boolean;
	handleSetAppSidebarNone: any;
	handleSetAppSidebarWide: any;
	handleSetAppSidebarLight: any;
	handleSetAppSidebarMinified: any;
	handleSetAppSidebarTransparent: any;
	handleSetAppSidebarSearch: any;
	handleSetAppSidebarFixed: any;
	handleSetAppSidebarGrid: any;
	toggleAppSidebarMinify: any;
	toggleAppSidebarMobile: any;
	
	appContentNone: boolean;
	appContentClass: string;
	appContentFullHeight: boolean;
	handleSetAppContentNone: any;
	handleSetAppContentClass: any;
	handleSetAppContentFullHeight: any;
	
	appTopMenu: boolean;
	appTopMenuMobileToggled: boolean;
	toggleAppTopMenuMobile: any;
	handleSetAppTopMenu: any;
	
	appSidebarEnd: boolean;
	appSidebarEndToggled: boolean;
	appSidebarEndMobileToggled: boolean;
	toggleAppSidebarEnd: any;
	toggleAppSidebarEndMobile: any;
	handleSetAppSidebarEnd: any;
	
	handleSetAppBoxedLayout: any;
	handleSetAppDarkMode: any;
	handleSetAppGradientEnabled: any;
	handleSetAppTheme: any;
	
	handleSetColor: any;

	font: any;
	color: any;

	//////////////////////////////
	customPopup: boolean;
  status: string[];
  status_dropdown: string[];
  kyc_id: string;
  id: string;
  date_from: string;
  date_to: string;
  customer: string;
  first_name:string;
  last_name:string;
  bank_account: string;
  kyc_status: string;
  bank_code: string;
  type_source: string;
  partner_id: string;  
  reference: string;  
  document_number: string;  
  customer_name: string;  
 type_document: string;  
  predifinedNote: string[];

  customer_cs: string;
  bank_account_cs: string;
  phone_number_cs: string;
  document_type_cs: string;
  document_number_cs: string;
  bank_account_currency_cs: string;
  date_from_cs: string;
  date_to_cs: string;
  payment_id_cs: string;
  payment_bank_code_cs: string;

  set_status: any;
  set_status_dropdown: any;
  set_kyc_id: any;
  set_date_from: any;
  set_date_to: any;
  set_customer: any;
  set_first_name:any;
  set_last_name:any;
  set_bank_account: any;
  set_kyc_status: any;
  set_bank_code: any;
  set_type_source: any;
  set_partner_id: any;
  set_PredifinedNote: any;
  set_customer_name: any;
  set_reference: any;
  set_document_number: any;
  set_type_document: any;
  handleCustomPopupView: any;

  set_customer_cs: any;
  set_bank_account_cs: any;
  set_phone_number_cs: any;
  set_document_type_cs: any;
  set_document_number_cs: any;
  set_bank_account_currency_cs: any;
  set_date_from_cs: any;
  set_date_to_cs: any;
  set_payment_id_cs: any;
  set_payment_bank_code_cs: any;
	handleLogOutApp: () => void,
	handleSetUserDataLogin: any;
	userDataLogin: IUserDataLogin;
	isUserInactive: boolean;
	showModalLogOut: boolean;

  set_id: any;
}

const objStorageInteractor = new storageInteractor();

class App extends React.Component<any, IAppState> {
	timerForRefreshToken: number | undefined;
	timerTimeForRefreshToken: number = 60 * 1000;
	
	timerTime1Sec: number = 1000;
	timerTimeValidateInactivity: number = 1000;

	inactivityTimer: number | undefined;
	timeUserInactive: number = 0;

	timerLast60Sec: number | undefined;
	lastSecInactiviteValue: number = 60;

	beginDateValueCountdown: Date = new Date();

	constructor(props: any) {
		super(props);
		
		const toggleAppSidebarMinify = (e: any) => {
			e.preventDefault();
			this.setState(state => ({
				appSidebarMinify: !this.state.appSidebarMinify
			}));
			if (localStorage) {
				localStorage.appSidebarMinify = !this.state.appSidebarMinify;
			}
		}
		const toggleAppSidebarMobile = (e: any) => {
			e.preventDefault();
			this.setState(state => ({
				appSidebarMobileToggled: !this.state.appSidebarMobileToggled
			}));
		}
		const handleSetAppSidebarNone = (value: any) => {
			this.setState(state => ({
				appSidebarNone: value
			}));
		}
		const handleSetAppSidebarWide = (value: any) => {
			this.setState(state => ({
				appSidebarWide: value
			}));
		}
		const handleSetAppSidebarLight = (value: any) => {
			this.setState(state => ({
				appSidebarLight: value
			}));
		}
		const handleSetAppSidebarTransparent = (value: any) => {
			this.setState(state => ({
				appSidebarTransparent: value
			}));
		}
		const handleSetAppSidebarSearch = (value: any) => {
			this.setState(state => ({
				appSidebarSearch: value
			}));
		}
	
		const toggleAppSidebarEnd = (e: any) => {
			e.preventDefault();
			this.setState(state => ({
				appSidebarEndToggled: !this.state.appSidebarEndToggled
			}));
		}
		const toggleAppSidebarEndMobile = (e: any) => {
			e.preventDefault();
			this.setState(state => ({
				appSidebarEndMobileToggled: !this.state.appSidebarEndMobileToggled
			}));
		}
		const handleSetAppSidebarEnd = (value: any) => {
			this.setState(state => ({
				appSidebarEnd: value
			}));
		}
		
		const handleSetAppContentNone = (value: any) => {
			this.setState(state => ({
				appContentNone: value
			}));
		}
		const handleSetAppContentClass = (value: any) => {
			this.setState(state => ({
				appContentClass: value
			}));
		}
		const handleSetAppContentFullHeight = (value: any) => {
			this.setState(state => ({
				appContentFullHeight: value
			}));
		}
		
		const handleSetAppHeaderNone = (value: any) => {
			this.setState(state => ({
				appHeaderNone: value
			}));
		}

		const handleSetAppHeaderMegaMenu = (value: any) => {
			this.setState(state => ({
				appHeaderMegaMenu: value
			}));
		}
		const handleSetAppHeaderLanguageBar = (value: any) => {
			this.setState(state => ({
				appHeaderLanguageBar: value
			}));
		}
		const handleSetAppTopMenu = (value: any) => {
			this.setState(state => ({
				appTopMenu: value
			}));
		}
		const toggleAppTopMenuMobile = (e: any) => {
			e.preventDefault();
			
			slideToggle(document.querySelector('.app-top-menu'));
		}

		const handleSetAppBoxedLayout = (value: any) => {
			if (value === true) {
				document.body.classList.add('boxed-layout');
			} else {
				document.body.classList.remove('boxed-layout');
			}
		}

		const handleSetAppHeaderFixed = (value: any) => {
			this.handleSetAppHeaderFixed(value);
		}

		const handleSetAppSidebarFixed = (value: any) => {
			this.handleSetAppSidebarFixed(value);
		}

		const handleSetAppSidebarMinified = (value: any) => {
			this.handleSetAppSidebarMinified(value);
		}

		const handleSetAppSidebarGrid = (value: any) => {
			this.handleSetAppSidebarGrid(value);
		}

		const handleSetAppGradientEnabled = (value: any) => {
			this.handleSetAppGradientEnabled(value);
		}

		const handleSetAppHeaderInverse = (value: any) => {
			this.handleSetAppHeaderInverse(value);
		}

		const handleSetAppDarkMode = (value: any) => {
			this.handleSetAppDarkMode(value);
		}

		const handleSetAppTheme = (value: any) => {
			this.handleSetAppTheme(value);
		}

		const handleSetColor = () => {
			this.handleSetColor();
		}

		////New changes///
		const handleCustomPopupView = (value: any) => {
      this.setState((state) => ({
        customPopup: value,
      }));
    };
		const set_status = (value: any) => {
      this.setState((state) => ({ status: value }));
    };
    const set_status_dropdown = (value: any) => {
      this.setState((state) => ({ status_dropdown: value }));
    };
    const set_kyc_id = (value: any) => {
      this.setState((state) => ({ kyc_id: value }));
    };
	const set_id = (value: any) => {
		this.setState((state) => ({ id: value }));
	  };
    const set_date_from = (value: any) => {
      this.setState((state) => ({ date_from: value }));
    };
    const set_date_to = (value: any) => {
      this.setState((state) => ({ date_to: value }));
    };
    const set_customer = (value: any) => {
      this.setState((state) => ({ customer: value }));
    };

	const set_first_name = (value: any) => {
		this.setState((state) => ({ first_name: value }));
	  };

	const set_last_name = (value: any) => {
		this.setState((state) => ({ last_name: value }));
	  };
  
	
    const set_bank_account = (value: any) => {
      this.setState((state) => ({ bank_account: value }));
    };
		const set_kyc_status = (value: any) => {
      this.setState((state) => ({ kyc_status: value }));
    };
    const set_bank_code = (value: any) => {
      this.setState((state) => ({ bank_code: value }));
    };
	const set_type_source = (value: any) => {
		this.setState((state) => ({ type_source: value }));
	  };
    const set_partner_id = (value: any) => {
      this.setState((state) => ({ partner_id: value }));
    };
	const set_PredifinedNote = (value: any) => {
      this.setState((state) => ({ predifinedNote: value }));
    };
	const set_customer_name = (value: any) => {
		this.setState((state) => ({ customer_name: value }));
	  };
	const set_reference = (value: any) => {
		this.setState((state) => ({ reference: value }));
	  };

	  const set_document_number = (value: any) => {
		this.setState((state) => ({ document_number: value }));
	  };
	  const set_type_document = (value: any) => {
		this.setState((state) => ({ type_document: value }));
	  };

		const set_customer_cs = (value: any) => {
      this.setState((state) => ({ customer_cs: value }));
    };
    const set_bank_account_cs = (value: any) => {
      this.setState((state) => ({ bank_account_cs: value }));
    };
    const set_phone_number_cs = (value: any) => {
      this.setState((state) => ({ phone_number_cs: value }));
    };
	const set_document_type_cs = (value: any) => {
      this.setState((state) => ({ document_type_cs: value }));
    };
	const set_document_number_cs = (value: any) => {
      this.setState((state) => ({ document_number_cs: value }));
    };
	const set_bank_account_currency_cs = (value: any) => {
      this.setState((state) => ({ bank_account_currency_cs: value }));
    };
	const set_date_from_cs = (value: any) => {
      this.setState((state) => ({ date_from_cs: value }));
    };
	const set_date_to_cs = (value: any) => {
      this.setState((state) => ({ date_to_cs: value }));
    };
	const set_payment_id_cs = (value: any) => {
      this.setState((state) => ({ payment_id_cs: value }));
    };
	const set_payment_bank_code_cs = (value: any) => {
      this.setState((state) => ({ payment_bank_code_cs: value }));
    };
		
		const handleSetUserDataLogin = (
      action: any,
      isAuthenticated: boolean,
      isAuthenticatedDuo: boolean
    ) => {
			userIsAuthenticated(isAuthenticated);

			this.setState((state) => ({
        userDataLogin: {
          userData: action.data,
          authenticated: isAuthenticated,
          authenticatedDuo: isAuthenticatedDuo,
        },
      }));
    };

		const handleLogOutApp = () => {
			objStorageInteractor.logOut_localStore();
    	handleSetUserDataLogin({ data: {} }, false, false);
		}

		function GetUserDataLogin(): IUserDataLogin {
      let objUserDataLogin: IUserDataLogin = {
        userData: {
          user_name: "",
          full_name: "",
          email: "",
          user_tipo_id: "",
          user_tipo_des: "",
          required_duo: "",
          data_host: "",
          data_sign_request: "",
					inactivity_time: 5
        },
        authenticated: false,
        authenticatedDuo: false,
      };

      return objUserDataLogin;
    }
		
		this.state = {
			appTheme: '',
			appDarkMode: false,
			appGradientEnabled: false,
			
			appHeaderNone: false,
			appHeaderFixed: true,
			appHeaderInverse: false,
			appHeaderMegaMenu: false,
			appHeaderLanguageBar: false,
			hasScroll: false,
			handleSetAppHeaderNone: handleSetAppHeaderNone,
			handleSetAppHeaderInverse: handleSetAppHeaderInverse,
			handleSetAppHeaderLanguageBar: handleSetAppHeaderLanguageBar,
			handleSetAppHeaderMegaMenu: handleSetAppHeaderMegaMenu,
			handleSetAppHeaderFixed: handleSetAppHeaderFixed,
			
			appSidebarNone: false,
			appSidebarWide: false,
			appSidebarLight: false,
			appSidebarMinify: false,
			appSidebarMobileToggled: false,
			appSidebarTransparent: false,
			appSidebarSearch: false,
			appSidebarFixed: true,
			appSidebarGrid: false,
			handleSetAppSidebarNone: handleSetAppSidebarNone,
			handleSetAppSidebarWide: handleSetAppSidebarWide,
			handleSetAppSidebarLight: handleSetAppSidebarLight,
			handleSetAppSidebarMinified: handleSetAppSidebarMinified,
			handleSetAppSidebarTransparent: handleSetAppSidebarTransparent,
			handleSetAppSidebarSearch: handleSetAppSidebarSearch,
			handleSetAppSidebarFixed: handleSetAppSidebarFixed,
			handleSetAppSidebarGrid: handleSetAppSidebarGrid,
			toggleAppSidebarMinify: toggleAppSidebarMinify,
			toggleAppSidebarMobile: toggleAppSidebarMobile,
			
			appContentNone: false,
			appContentClass: '',
			appContentFullHeight: false,
			handleSetAppContentNone: handleSetAppContentNone,
			handleSetAppContentClass: handleSetAppContentClass,
			handleSetAppContentFullHeight: handleSetAppContentFullHeight,
			
			appTopMenu: false,
			appTopMenuMobileToggled: false,
			toggleAppTopMenuMobile: toggleAppTopMenuMobile,
			handleSetAppTopMenu: handleSetAppTopMenu,
			
			appSidebarEnd: false,
			appSidebarEndToggled: false,
			appSidebarEndMobileToggled: false,
			toggleAppSidebarEnd: toggleAppSidebarEnd,
			toggleAppSidebarEndMobile: toggleAppSidebarEndMobile,
			handleSetAppSidebarEnd: handleSetAppSidebarEnd,
			
			handleSetAppBoxedLayout: handleSetAppBoxedLayout,
			handleSetAppDarkMode: handleSetAppDarkMode,
			handleSetAppGradientEnabled: handleSetAppGradientEnabled,
			handleSetAppTheme: handleSetAppTheme,
			
			handleSetColor: handleSetColor,
			
			font: {
				family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
				size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
				weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
			},
			color: {
				componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
				componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
				dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
				light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
				blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
				indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
				purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
				pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
				red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
				orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
				yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
				green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
				success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
				teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
				cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
				white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
				gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
				lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
				gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
				gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
				gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
				gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
				gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
				gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
				gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
				gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
				gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
				black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
				componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
				componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
				darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
				lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
				blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
				indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
				purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
				pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
				redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
				orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
				yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
				greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
				successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
				tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
				cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
				whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
				grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
				limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
				gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
				gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
				gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
				gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
				gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
				gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
				gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
				gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
				gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
				blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
			},
			///////
			customPopup: false,
			handleCustomPopupView: handleCustomPopupView,

			status: [],
      status_dropdown: [],
      kyc_id: "",
	  id: "",
      date_from: "",
      date_to: "",
      customer: "",
	  first_name:"",
	  last_name:"",
      bank_account: "",
	  type_source: "",
      kyc_status: "",
      bank_code: "",
      partner_id: "",
	  reference: "",
	  document_number: "",
	  type_document: "",
	  customer_name: "",
      predifinedNote: [],

      set_status: set_status,
      set_status_dropdown: set_status_dropdown,
      set_kyc_id: set_kyc_id,
	  set_id: set_id,
      set_date_from: set_date_from,
      set_date_to: set_date_to,
      set_customer: set_customer,
	  set_first_name:set_first_name,
	  set_last_name:set_last_name,
      set_bank_account: set_bank_account,
      set_kyc_status: set_kyc_status,
      set_bank_code: set_bank_code,
	  set_type_source: set_type_source,
      set_partner_id: set_partner_id,
	  set_reference: set_reference,
	  set_document_number: set_document_number,
	  set_type_document: set_type_document,
      set_PredifinedNote: set_PredifinedNote,
	  set_customer_name: set_customer_name,

      customer_cs: "",
      bank_account_cs: "",
      phone_number_cs: "",
	  document_type_cs: "",
	  document_number_cs: "",
	  bank_account_currency_cs: "",
	  date_from_cs: "",
	  date_to_cs: "",
	  payment_id_cs: "",
	  payment_bank_code_cs: "",
      set_customer_cs: set_customer_cs,
      set_bank_account_cs: set_bank_account_cs,
      set_phone_number_cs: set_phone_number_cs,
	  set_document_type_cs: set_document_type_cs,
	  set_document_number_cs: set_document_number_cs,
	  set_bank_account_currency_cs: set_bank_account_currency_cs,
	  set_date_from_cs: set_date_from_cs,
	  set_date_to_cs: set_date_to_cs,
	  set_payment_id_cs: set_payment_id_cs,
	  set_payment_bank_code_cs: set_payment_bank_code_cs,
			handleLogOutApp: handleLogOutApp,
			handleSetUserDataLogin: handleSetUserDataLogin,
			userDataLogin: GetUserDataLogin(),
			isUserInactive: false,
			showModalLogOut: false
		};

		this.handleActivity = this.handleActivity.bind(this);

		const userIsAuthenticated = (isAuthenticated: boolean) => {
			if (isAuthenticated) {
				this.startTimeIsUserInactive();
				this.starTimerForRefreshToken();
			} else {
				this.endTimeIsUserInactive();
				this.endTimerForRefreshToken();
			}
		}
	}

	//
	starTimerForRefreshToken = () => {
		this.timerForRefreshToken = window.setTimeout(() => {
			this.endTimerForRefreshToken();

			let token_security: string | null =
			objStorageInteractor.getTokenSecurity();

			if (token_security != null) {
				pingUser().then((response: any) => {
					if (response.status === 200) {
						objStorageInteractor.setTokenSecurity(response.data.token);
						this.starTimerForRefreshToken();
					} else {
						objStorageInteractor.logOut_localStore(true);
					}
				});
			}

		}, this.timerTimeForRefreshToken);
	}
	endTimerForRefreshToken = () => {
		clearTimeout(this.timerForRefreshToken);
	}
	//

	//
	startTimeIsUserInactive = () => {
    document.addEventListener('mousemove', this.handleActivity);
    document.addEventListener('keydown', this.handleActivity);
		this.handleActivity();
	}

	endTimeIsUserInactive = () => {
		this.finishTimerValidateInactivity();
		this.finishTimerLast60Sec();
		document.removeEventListener('mousemove', this.handleActivity);
    document.removeEventListener('keydown', this.handleActivity);
	}

	handleActivity() {
		this.finishTimerValidateInactivity();
		this.beginValidateInactivity();
  }

	beginValidateInactivity = () => {
		const saveDateValue = new Date();
		objStorageInteractor.setCountdownDateValue(saveDateValue.getTime().toString());
		
		this.inactivityTimer = window.setInterval(() => {
      this.validateInactivity();
    }, this.timerTimeValidateInactivity);
	}

	validateInactivity = () => {
		if (this.validateTimeTranscurred()) {
			this.setState({ isUserInactive: true }, () => { 
				this.endTimeIsUserInactive();
				this.beginDateValueCountdown = new Date();
				this.modalCountdown();
			});
		}
	}

	contdownSecondTranscurred = (): number => {
		const currentDateValue = new Date();
		const saveDateValue: number = Number(objStorageInteractor.getCountdownDateValue());
		const diference = currentDateValue.getTime() - saveDateValue;
		return Math.trunc(diference / 1000);
	}

	validateTimeTranscurred = (): boolean => {
		const secondsTrans: number = this.contdownSecondTranscurred();
		const inactivityTime: number = ((((this.state.userDataLogin.userData.inactivity_time === 0 ? 5 : this.state.userDataLogin.userData.inactivity_time)) - 1) * 60)
		return (secondsTrans >= inactivityTime);
	}

	modalCountdown = () => {
		const secondsTrans: number = this.contdownSecondTranscurred();
		const inactivityTime: number = (((this.state.userDataLogin.userData.inactivity_time === 0 ? 5 : this.state.userDataLogin.userData.inactivity_time)) * 60);
		const elementSpan = document.getElementById("spInactiveTimer");
		if (elementSpan instanceof HTMLElement) {
			var timeTranscurrier = (inactivityTime - secondsTrans);
			elementSpan.innerText = timeTranscurrier.toString();
			if (timeTranscurrier <= 0) {
				this.logoutForInactivity();
			}
		}

		if (!this.validateTimeTranscurred()) {this.extendSession();}
		if (objStorageInteractor.getTokenSecurity() === null) {this.logoutForInactivity();}

		this.timerLast60Sec = window.setTimeout(() => {
			this.modalCountdown();
		}, this.timerTime1Sec);
	}

	finishTimerValidateInactivity = () => {
		clearTimeout(this.inactivityTimer);
	}

	finishTimerLast60Sec = () => {
		clearTimeout(this.timerLast60Sec);
	}

	extendSession = () => {
		this.setState({ isUserInactive: false }, () => {
			this.finishTimerLast60Sec();
			this.startTimeIsUserInactive();
		});
	}
	logoutForInactivity = () => {
		this.setState({ showModalLogOut: true}, () => {
			this.setState({ isUserInactive: false }, () => {
				this.finishTimerLast60Sec();
				this.state.handleLogOutApp();
				//objStorageInteractor.logOut_localStore(true);
			});
		});
	}
	//

	handleSetAppHeaderFixed = (value: any) => {
		if (value === false && this.state.appSidebarFixed) {
			alert('Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.');
			this.setState(state => ({
				appSidebarFixed: false
			}));
			if (localStorage) {
				localStorage.appSidebarFixed = false;
			}
		}
		this.setState(state => ({
			appHeaderFixed: value
		}));
		if (localStorage) {
			localStorage.appHeaderFixed = value;
		}
	}

	handleSetAppSidebarFixed = (value: any) => {
		if (value === true && !this.state.appHeaderFixed) {
			alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');
			this.setState(state => ({
				appHeaderFixed: true
			}));
			if (localStorage) {
				localStorage.appHeaderFixed = true;
			}
		}
		this.setState(state => ({
			appSidebarFixed: value
		}));
		if (localStorage) {
			localStorage.appSidebarFixed = value;
		}
	}

	handleSetAppSidebarMinified = (value: any) => {
		this.setState(state => ({
			appSidebarMinify: value
		}));
	}

	handleSetAppSidebarGrid = (value: any) => {
		this.setState(state => ({
			appSidebarGrid: value
		}));
		if (localStorage) {
			localStorage.appSidebarGrid = value;
		}
	}

	handleSetAppGradientEnabled = (value: any) => {
		this.setState(state => ({
			appGradientEnabled: value
		}));
		if (localStorage) {
			localStorage.appGradientEnabled = value;
		}
	}

	handleSetAppHeaderInverse = (value: any) => {
		this.setState(state => ({
			appHeaderInverse: value
		}));
		if (localStorage) {
			localStorage.appHeaderInverse = value;
		}
	}

	handleSetAppDarkMode = (value: any) => {
		if (value === true) {
			document.body.classList.add('dark-mode');
		} else {
			document.body.classList.remove('dark-mode');
		}
		this.setState(state => ({ appDarkMode: value }));
		this.handleSetColor();
		if (localStorage) {
			localStorage.appDarkMode = value;
		}
	}

	handleSetAppTheme = (value: any) => {
		var newTheme = 'theme-' + value;
		for (var x = 0; x < document.body.classList.length; x++) {
			if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
				document.body.classList.remove(document.body.classList[x]);
			}
		}
		document.body.classList.add(newTheme);
		
		if (localStorage && value) {
			localStorage.appTheme = value;
		}
	}

	handleSetColor = () => {
		this.setState(state => ({
			color: {
				componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
				componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
				dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
				light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
				blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
				indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
				purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
				pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
				red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
				orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
				yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
				green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
				success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
				teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
				cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
				white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
				gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
				lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
				gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
				gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
				gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
				gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
				gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
				gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
				gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
				gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
				gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
				black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
				componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
				componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
				darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
				lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
				blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
				indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
				purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
				pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
				redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
				orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
				yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
				greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
				successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
				tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
				cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
				whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
				grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
				limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
				gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
				gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
				gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
				gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
				gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
				gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
				gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
				gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
				gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
				blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim()
			}
		}));
	}

	reviewIfUserIsLogin = () => {
		let token_security: string | null =
		objStorageInteractor.getTokenSecurity();

		if (token_security != null) {
			pingUser().then((response: any) => {
				if (response.status === 200) {
					objStorageInteractor.setTokenSecurity(response.data.token);
					this.state.handleSetUserDataLogin(response, true, true);
				} else {
					objStorageInteractor.logOut_localStore();
					this.state.handleSetUserDataLogin(response, false, false);
				}
			});
		}
	}


	componentDidMount() {
		this.handleSetColor();
		this.handleSetAppTheme(this.state.appTheme);
		if (this.state.appDarkMode) {
			this.handleSetAppDarkMode(true);
		}
		
    window.addEventListener('scroll', this.handleScroll);
    
		if (localStorage) {
			if (typeof localStorage.appTheme !== 'undefined') {
				document.body.classList.add('theme-'+ localStorage.appTheme);
			}
			if (typeof localStorage.appDarkMode !== 'undefined') {
				this.handleSetAppDarkMode((localStorage.appDarkMode === 'true') ? true : false);
			}
			if (typeof localStorage.appHeaderInverse !== 'undefined') {
				this.handleSetAppHeaderInverse((localStorage.appHeaderInverse === 'true') ? true : false);
			}
			if (typeof localStorage.appGradientEnabled !== 'undefined') {
				this.handleSetAppGradientEnabled((localStorage.appDarkMode === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarGrid !== 'undefined') {
				this.handleSetAppSidebarGrid((localStorage.appSidebarGrid === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarMinify !== 'undefined') {
				this.handleSetAppSidebarMinified((localStorage.appSidebarMinify === 'true') ? true : false);
			}
			if (typeof localStorage.appSidebarFixed !== 'undefined') {
				this.handleSetAppSidebarFixed((localStorage.appSidebarFixed === 'true') ? true : false);
			}
			if (typeof localStorage.appHeaderFixed !== 'undefined') {
				this.handleSetAppHeaderFixed((localStorage.appHeaderFixed === 'true') ? true : false);
			}
		}

		this.reviewIfUserIsLogin();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  
  handleScroll = () => {
  	if (window.scrollY > 0) {
  		this.setState(state => ({
				hasScroll: true
			}));
  	} else {
  		this.setState(state => ({
				hasScroll: false
			}));
  	}
  	var elm = document.getElementsByClassName('nvtooltip');
  	for (var i = 0; i < elm.length; i++) {
  		elm[i].classList.add('d-none');
  	}
  }
	
	render() {

		return (
			<AppSettings.Provider value={this.state}>
				<div className={
					'app ' +
					(this.state.appGradientEnabled ? 'app-gradient-enabled ' : '') + 
					(this.state.appHeaderNone ? 'app-without-header ' : '') + 
					(this.state.appHeaderFixed && !this.state.appHeaderNone ? 'app-header-fixed ' : '') + 
					(this.state.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
					(this.state.appSidebarNone ? 'app-without-sidebar ' : '') + 
					(this.state.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
					(this.state.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
					(this.state.appSidebarLight ? 'app-with-light-sidebar ' : '') +
					(this.state.appSidebarMinify ? 'app-sidebar-minified ' : '') + 
					(this.state.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') + 
					(this.state.appTopMenu ? 'app-with-top-menu ' : '') + 
					(this.state.appContentFullHeight ? 'app-content-full-height ' : '') + 
					(this.state.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') + 
					(this.state.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') + 
					(this.state.hasScroll ? 'has-scroll ' : '')
				}>
					{!this.state.appHeaderNone && (<Header />)}
					{!this.state.appSidebarNone && (<Sidebar />)}
					{!this.state.appContentNone && (<Content />)}
          <ModalGeneral
            isModalOpen={this.state.isUserInactive}
            onlyModal={false}
						addZIndexTop={true}
            type="warning"
						cancelBtnText='Extend my Session'
						confirmBtnText='Logout Now'
						onCancel={this.extendSession}
						onConfirm={this.logoutForInactivity}
          >
            <div>
              <h3>Your session will timeout in <span id="spInactiveTimer">{this.lastSecInactiviteValue}</span> seconds!</h3>
            </div>
          </ModalGeneral>
					<ModalGeneral
            isModalOpen={this.state.showModalLogOut}
            onlyModal={false}
						addZIndexTop={true}
            type="info"
						title='Oh No!'
						confirmBtnText='Login Again'
						onConfirm={() => {this.setState({ showModalLogOut: false})}}
          >
            <div>
              <h3>Your session has timed out.</h3>
            </div>
          </ModalGeneral>
				</div>
			</AppSettings.Provider>
		)
	}
}

export default App;